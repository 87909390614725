<template>
  <div class="bucket">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>存储桶管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" @click="add">添加存储桶</el-button>
      </div>
      <div class="table-area">
        <el-table :data="pageData" border>
          <el-table-column fixed type="index" label="#" width="70"></el-table-column>
          <el-table-column prop="Name" label="存储桶名称"></el-table-column>
          <el-table-column prop="Location" label="地区"></el-table-column>
          <el-table-column prop="CreationDate" label="创建时间"></el-table-column>
          <el-table-column prop="BucketType" label="存储桶类型"></el-table-column>
          <el-table-column prop="Cdn" label="加速CDN"></el-table-column>
          <el-table-column prop="mark" label="备注"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="del(scope.row.Name)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bucket',
  data () {
    return {
      pageData: []
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/bucket-list')
      if (res.code === 200) {
        this.pageData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    add () {
      this.$router.push('/admin/setup/bucket/add')
    },
    edit (mid) {
      this.$router.push('/admin/setup/bucket/edit/' + mid)
    },
    async del (name) {
      const { data: res } = await this.$http.delete('/bucket', { params: { bucket_name: name } })
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  margin-top: 30px;
}
.table-area{
  margin-top: 30px;
}
</style>
